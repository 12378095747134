import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter';
import gogApi from './gogApi';
import defaultAlbumsService from './defaultAlbumsService';
import uuid from 'uuid';

const convertCoverToGalleryFormat = (cover: GogPhoto) => {
  const isSecuredCover = cover.metadata && cover.metadata.isSecure;
  if (cover.metadata?.type === 'video') {
    cover.url = cover.metadata.posters[0].url;
  }
  return {
    id: cover.photoId,
    dataType: 'Photo',
    name: cover.url,
    mediaUrl: `https://static.wixstatic.com/media/${cover.url}`,
    photoMetadata: cover.metadata
      ? {
          height: cover.metadata.height,
          width: cover.metadata.width,
        }
      : { height: 300, width: 300 },
    isSecure: isSecuredCover,
    imageToken: isSecuredCover && (cover.imageToken || cover.token),
  };
};

export const parseAlbumsCovers = (albums: GogAlbum[]) => {
  let albumsCovers: GalleryItem[] = [];
  const coverIdToAlbum: { [id: string]: GogAlbum } = {};
  const coverPhotosForGallery: GogPhotoForGallery[] = [];
  albums.forEach((album: any) => {
    try {
      let coverPhoto;
      const oldCover = album.sets[0].photos[0];
      if (oldCover) {
        coverPhoto = convertCoverToGalleryFormat(oldCover);
      } else {
        coverPhoto = {
          id: uuid(),
          dataType: 'Photo',
          name: 'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png',
          mediaUrl: `https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png`,
          photoMetadata: { height: 300, width: 300 },
          isSecure: false,
        };
      }
      if (coverPhoto.id) {
        coverPhotosForGallery.push(coverPhoto);
        coverIdToAlbum[coverPhoto.id] = album;
      }
    } catch (e: any) {
      console.log('Failed to convert album cover of album ' + album.id);
    }
  });

  albumsCovers = serverItemsToProGallery(coverPhotosForGallery);
  albumsCovers.forEach((item: any, idx: number) => {
    try {
      const watermark = albums[idx].settings.watermark;
      item.metaData.title = albums[idx].editables.title;
      item.metaData.description = albums[idx].editables.date;
      item.orderIndex = albums[idx].orderIndex;
      if (watermark && coverPhotosForGallery[idx].isSecure) {
        item.metaData.watermark = watermark;
      }
    } catch (e: any) {
      try {
        console.log('Failed to parse album data ' + albums[idx].albumId);
      } catch (err) {}
    }
  });
  return { albumsCovers, coverIdToAlbum };
};

const gogHelper = (httpClient: any, isViewer: boolean = false) => {
  const { getGogAlbumsForSettings, getPublishedAlbums, getGogAlbums } =
    gogApi(httpClient);
  const { getDefaultAlbums } = defaultAlbumsService(httpClient, isViewer);

  const getGogDataAndCovers = async (
    instanceId: string,
    instance: string,
    compId: string,
    defaultAlbumsIds: string[],
    showNewAlbums: boolean,
    shouldStopCallingAppSettingsInGog?: boolean,
  ) => {
    let gogData;
    // Trying to fix FIXME
    if (shouldStopCallingAppSettingsInGog) {
      gogData = await getGogAlbums(instanceId, instance, compId, isViewer);
    } else {
      // FIXME: temporarily changed getGogAlbums to getGogAlbumsForSettings to fix live site sorting
      gogData = await getGogAlbumsForSettings(
        instanceId,
        instance,
        compId,
        isViewer,
      );
    }
    if (!gogData.albums || gogData.albums.length === 0) {
      gogData.albums = await getDefaultAlbums(
        defaultAlbumsIds,
        instance,
        showNewAlbums,
      );
    }

    const { albumsCovers, coverIdToAlbum } = parseAlbumsCovers(gogData.albums);
    return { gogData, albumsCovers, coverIdToAlbum };
  };

  const getAlbumsForDisplaySettings = async (
    instanceId: string,
    instance: string,
    compId: string,
  ) => {
    const { albums: gogAlbums } = await getGogAlbumsForSettings(
      instanceId,
      instance,
      compId,
      isViewer,
    );

    return gogAlbums;
  };

  const getAdiData = async (
    instance: string,
    instanceId: string,
    compId: string,
    showNewAlbums: boolean,
  ) => {
    const [{ albums: allPublishedAlbums }, { gogData }] = await Promise.all([
      getPublishedAlbums(instance, instanceId),
      getGogDataAndCovers(instanceId, instance, compId, [], showNewAlbums),
    ]);
    const gogAlbums = gogData.albums;
    if (allPublishedAlbums && allPublishedAlbums.length !== 0) {
      allPublishedAlbums.forEach((album: any) => {
        album.visible = gogAlbums.some(
          (gogAlbum: any) => gogAlbum.albumId === album.albumId,
        );
      });
    }

    return { allPublishedAlbums, gogAlbums };
  };

  return {
    getGogDataAndCovers,
    getAdiData,
    getAlbumsForDisplaySettings,
  };
};

export default gogHelper;
