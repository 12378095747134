import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import application from '../../../.application.json';
import gogHelper, { parseAlbumsCovers } from '../../services/gogHelper';
import defaultAlbumsService from '../../services/defaultAlbumsService';
import { manageWarmupData } from '../../services/utils';
import { getSortedAlbums } from '../../services/albumsSorter';
import { defaultStaticMediaUrls } from '../../consts/generalConsts';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const {
    appParams,
    setProps,
    config,
    compId,
    platformAPIs: { bi },
    wixCodeApi,
  } = controllerConfig;
  const biToken = bi?.metaSiteId;
  const { environment, httpClient, experiments } = flowAPI;
  const { appName } = application;
  const { language, isViewer } = environment;
  const { baseUrls = {}, instance, instanceId } = appParams;
  const { getGogDataAndCovers } = gogHelper(httpClient, isViewer);
  const { getDefaultAlbums } = defaultAlbumsService(httpClient, isViewer);

  const staticMediaUrls =
    // @ts-expect-error
    flowAPI.controllerConfig.platformAPIs.topology?.media ||
    defaultStaticMediaUrls;

  let albumsCovers: GalleryItem[] = [];
  let coverIdToAlbum: { [id: string]: GogAlbum } = {};
  let gogData: GogData | { albums: GogAlbum[] } = { albums: [] };
  const visitorId = bi?.visitorId;
  const userOwnership =
    flowAPI.controllerConfig.wixCodeApi.user.currentUser.role;

  const defaultAlbumsIds =
    config &&
    config.publicData &&
    config.publicData.APP &&
    config.publicData.APP.defaultAlbums &&
    config.publicData.APP.defaultAlbums.ids;

  const shouldStopCallingAppSettingsInGog = experiments.enabled(
    'specs.albums.stopCallingAppSettingsInGog',
  );

  const getGogDataAndCoversCallback = () => {
    const showNewAlbums = experiments.enabled(
      'specs.albums.newDefaultAlbumsUsers',
    );
    return getGogDataAndCovers(
      instanceId,
      instance,
      compId,
      defaultAlbumsIds,
      shouldStopCallingAppSettingsInGog,
      showNewAlbums,
    );
  };

  try {
    let result;
    if (experiments.enabled('specs.albums.warmupDataOOI')) {
      result = await manageWarmupData(
        getGogDataAndCoversCallback,
        'gogData',
        wixCodeApi.window.warmupData,
        environment.isSSR,
        compId,
      );
    } else {
      result = await getGogDataAndCoversCallback();
    }
    gogData = result.gogData;
    albumsCovers = result.albumsCovers;
    coverIdToAlbum = result.coverIdToAlbum;
  } catch (e: any) {
    console.error('Failed to fetch GoG data - ' + e.message);
  }

  const updateAlbumCovers = async (gogAlbums: GogAlbum[], sortBy: number) => {
    const {
      albumsCovers: _albumsCovers,
      coverIdToAlbum: _coverIdToAlbum,
    } = parseAlbumsCovers(gogAlbums);
    setProps({
      albumsCovers: getSortedAlbums(sortBy, _albumsCovers, _coverIdToAlbum),
      coverIdToAlbum: _coverIdToAlbum,
    });
  };

  const updateGoGAlbums = async (gogAlbums: GogAlbum[], sortBy: number) => {
    const showNewAlbums = experiments.enabled(
      'specs.albums.newDefaultAlbumsUsers',
    );
    let _gogAlbums = gogAlbums;
    if (!gogAlbums || gogAlbums.length === 0) {
      _gogAlbums = await getDefaultAlbums(
        defaultAlbumsIds,
        instance,
        showNewAlbums,
      );
    }
    updateAlbumCovers(_gogAlbums, sortBy);
    setProps({ gogAlbums });
  };
  const sortBy = config.style.styleParams.numbers.sortBy;
  albumsCovers = getSortedAlbums(sortBy, albumsCovers, coverIdToAlbum);

  const setHeightFunc = (height: number) => setProps({ dimensions: height });
  return {
    async pageReady() {
      setProps({
        appName,
        cssBaseUrl: baseUrls.staticsBaseUrl,
        mobile: environment.isMobile,
        language,
        instance,
        instanceId,
        biToken,
        setHeight: setHeightFunc,
        albumsCovers,
        gogAlbums: gogData.albums,
        coverIdToAlbum,
        visitorId,
        userOwnership,
        staticMediaUrls,
        inEditor: environment.isEditor,
        fetchAlbums: async () => {
          const showNewAlbums = experiments.enabled(
            'specs.albums.newDefaultAlbumsUsers',
          );
          return getGogDataAndCovers(
            instanceId,
            instance,
            compId,
            defaultAlbumsIds,
            shouldStopCallingAppSettingsInGog,
            showNewAlbums,
          );
        },
        updateGoGAlbums,
        updateAlbumCovers,
      });
    },
  };
};

export default createController;
